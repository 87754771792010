import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-179a67ab"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "absentPersonnel"
};
var _hoisted_2 = {
  class: "container"
};
var _hoisted_3 = {
  class: "userList"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_c_date_header = _resolveComponent("c-date-header");
  var _component_dept_list_item = _resolveComponent("dept-list-item");
  var _component_c_no_data = _resolveComponent("c-no-data");
  var _component_user_list_item = _resolveComponent("user-list-item");
  var _component_van_collapse = _resolveComponent("van-collapse");
  var _component_van_list = _resolveComponent("van-list");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_c_date_header, {
    title: "本日未出勤人员",
    type: 0,
    onConfirmDate: _ctx.confrimDate
  }, null, 8, ["onConfirmDate"]), _createElementVNode("div", _hoisted_3, [_ctx.deptName === 'stsj' ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 0
  }, [_ctx.absentUserList.length ? (_openBlock(true), _createElementBlock(_Fragment, {
    key: 0
  }, _renderList(_ctx.absentUserList, function (item, index) {
    return _openBlock(), _createBlock(_component_dept_list_item, {
      key: index,
      index: index,
      item: item
    }, null, 8, ["index", "item"]);
  }), 128)) : _createCommentVNode("", true), !_ctx.absentUserList.length ? (_openBlock(), _createBlock(_component_c_no_data, {
    key: 1
  })) : _createCommentVNode("", true)], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_ctx.absentUserList.length ? (_openBlock(), _createBlock(_component_van_list, {
    key: 0
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_van_collapse, {
        modelValue: _ctx.activeName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
          return _ctx.activeName = $event;
        }),
        accordion: ""
      }, {
        default: _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.absentUserList, function (item, index) {
            return _openBlock(), _createBlock(_component_user_list_item, {
              key: index,
              index: index,
              item: item
            }, null, 8, ["index", "item"]);
          }), 128))];
        }),
        _: 1
      }, 8, ["modelValue"])];
    }),
    _: 1
  })) : _createCommentVNode("", true), !_ctx.absentUserList.length ? (_openBlock(), _createBlock(_component_c_no_data, {
    key: 1
  })) : _createCommentVNode("", true)], 64))])])]);
}