/* unplugin-vue-components disabled */import "core-js/modules/es.number.constructor.js";
// import {
//   IAbsentUserItem,
//   IAbsentUserListItem
// } from '@/service/work/attendanceAnalysis/type';
export default defineComponent({
  name: 'DeptListItem',
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  setup: function setup() {
    return {};
  }
});