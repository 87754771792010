import hyRequest from '@/service';
import { IUserAttendanceInfo, IUserInfoNew } from './type';

// 个人详情
export function getUserData(params: { mobile: string }) {
  return hyRequest.post<IUserInfoNew>({
    url: '/communication/getUserInfoNew',
    data: params
  });
}

export function getUserData1(params: { userId: string }) {
  return hyRequest.post<IUserAttendanceInfo>({
    url: '/communication/getUserAttendanceInfo',
    data: params
  });
}

export function updateOrganizationNew(params: {
  id: string;
  responsibility: string;
}) {
  return hyRequest.post<string>({
    url: '/communication/updateOrganizationNew',
    data: params
  });
}
