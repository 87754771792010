/* unplugin-vue-components disabled */import _slicedToArray from "D:/code/org/guanjia/blueSkyReportForm/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import useRealTimeAttendance from './hooks/useRealTimeAttendance';
import useDepAttendanceInfo from './hooks/useDepAttendanceInfo';
import useDepAttendanceMonthInfo from './hooks/useDepAttendanceMonthInfo';
import { useEmptyStringFormat } from '@/hooks/fn';
import { BarEchartMul } from '@/components/page-echarts';
export default defineComponent({
  name: 'AttendanceAnalysis',
  components: {
    BarEchartMul: BarEchartMul
  },
  setup: function setup() {
    var emptyStringFormat = useEmptyStringFormat();
    var params = ref({
      date: dayjs().format('YYYY-MM-DD')
    });
    var _useRealTimeAttendanc = useRealTimeAttendance(params),
      _useRealTimeAttendanc2 = _slicedToArray(_useRealTimeAttendanc, 2),
      attendanceData = _useRealTimeAttendanc2[0],
      getAttendanceAsync = _useRealTimeAttendanc2[1];
    var _useDepAttendanceInfo = useDepAttendanceInfo(params),
      _useDepAttendanceInfo2 = _slicedToArray(_useDepAttendanceInfo, 2),
      depAttendanceInfo = _useDepAttendanceInfo2[0],
      getDepAttendanceInfoAsync = _useDepAttendanceInfo2[1];
    var monthParams = ref({
      date: dayjs().format('YYYY-MM-DD')
    });
    var _useDepAttendanceMont = useDepAttendanceMonthInfo(monthParams),
      _useDepAttendanceMont2 = _slicedToArray(_useDepAttendanceMont, 2),
      depAttendanceMonthInfo = _useDepAttendanceMont2[0],
      getDepAttendanceMonthInfoAsync = _useDepAttendanceMont2[1];
    var date = ref(new Date());
    var confirmDate = function confirmDate(value) {
      monthParams.value.date = dayjs(value).format('YYYY-MM-DD');
      getDepAttendanceMonthInfoAsync();
    };
    var mainPro = computed(function () {
      var lineData1 = depAttendanceMonthInfo.value.map(function (item) {
        return item.attendance;
      });
      var lineData2 = depAttendanceMonthInfo.value.map(function (item) {
        return item.leave_count;
      });
      var lineData3 = depAttendanceMonthInfo.value.map(function (item) {
        return item.late_count;
      });
      var xLabels = depAttendanceMonthInfo.value.map(function (item) {
        return item.dep_name;
      });
      var legendData = ['出勤率', '请假人天数', '迟到人天数'];
      return {
        xLabels: xLabels,
        lineData: [lineData1, lineData2, lineData3],
        legendData: legendData,
        colors: ['#82A3FF', '#5AC57F', '#EEC324'],
        grid: {
          left: 40,
          top: 60,
          bottom: 30,
          right: 20
        },
        customTooltip: true,
        tooptipFormatter: function tooptipFormatter(params) {
          var res = "\n              <div >\n                <div class=\"flex align-center\">\n                  <span class=\"ml-2\">".concat(params[0].axisValue, "</span>\n                </div>\n              ");
          params.forEach(function (item) {
            res += "\n                <div >\n                  ".concat(item.marker, "\n                  <span style=\"font-size: 12px;\">").concat(item.seriesName, "</span>\n                  <span style=\"font-weight:bold;font-size: 12px;\">").concat(item.value).concat(item.seriesName === '出勤率' ? '%' : '', "</span>\n                </div>\n              ");
          });
          res += '</div>';
          return res;
        }
      };
    });
    onMounted(function () {
      getAttendanceAsync();
      getDepAttendanceInfoAsync();
      getDepAttendanceMonthInfoAsync();
    });
    var router = useRouter();
    var jumpPage = function jumpPage(routeName) {
      if (!routeName) return;
      router.push({
        name: routeName
      });
    };
    return {
      attendanceData: attendanceData,
      emptyStringFormat: emptyStringFormat,
      depAttendanceInfo: depAttendanceInfo,
      confirmDate: confirmDate,
      date: date,
      depAttendanceMonthInfo: depAttendanceMonthInfo,
      mainPro: mainPro,
      jumpPage: jumpPage
    };
  }
});